import { Meta } from "../../meta"

export enum ServiceRequestState {
    NEW = "NEW",
    AUTHORIZED = "AUTHORIZED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED"
}

export enum ServiceRequestType {
    CREATION = "CREATION",
    MODIFICATION = "MODIFICATION"
}

export interface ServiceRequest extends Meta {
    _id: string
    name: string
    type: ServiceRequestType
    state: ServiceRequestState
    peerId: string
    internalUrl: string
    healthcheckUrl: string
    servicePath: string
    securityClass: number
    decreeNumber?: string
    rejectionReason?: string
    namespace: string
    fromDate: number
    toDate?: number
}
