export enum AccessPermissionEventType {
    ADD_ECHO = "urn:event:service:access-permission:add-echo",
    REQUEST_CREATION = "urn:event:service:access-permission:creation:request",
    MODIFY_PERMISSION = "urn:event:service:access-permission:modify",
    CANCEL_REQUEST = "urn:event:service:access-permission:request:cancel",
    AUTHORIZE_REQUEST = "urn:event:service:access-permission:request:authorize",
    REJECT_REQUEST = "urn:event:service:access-permission:request:reject",
    REVOKE_PERMISSION = "urn:event:service:access-permission:revoke",
    REGRANT_PERMISSION = "urn:event:service:access-permission:regrant",
    RESIGN_PERMISSION = "urn:event:service:access-permission:resign",
    CREATE_AUTH_TOKEN = "urn:event:service:authtoken:create",
    REVOKE_AUTH_TOKEN = "urn:event:service:authtoken:revoke",
    MODIFY_RATE_LIMIT = "urn:event:service:access-permission:rate-limit:modify"
}

export interface AccessPermissionEvent {
    _id: string
    ts: number
    type: AccessPermissionEventType
    actor: string
    requestId?: string
    meta?: Record<string, unknown>
}
