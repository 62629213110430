import { AccessPermissionState, ServiceRequestState, ServiceState } from "../../api/fny-api/contracts"

export const getIcon = (state: ServiceRequestState | ServiceState | AccessPermissionState): string => {
    switch (state) {
        case ServiceRequestState.NEW:
            return "fa-plus-circle text-success"
        case ServiceRequestState.AUTHORIZED:
            return "fa-check-circle-o text-success"
        case ServiceRequestState.CANCELLED:
            return "fa-times-circle-o text-error"
        case ServiceRequestState.REJECTED:
            return "fa-times-circle-o text-error"
        case ServiceState.AUTHORIZED:
        case ServiceState.REQUESTED:
        case ServiceState.REJECTED:
        case ServiceState.CANCELLED:
        case AccessPermissionState.AUTHORIZED:
            return "fa-check-circle-o text-success"
        case AccessPermissionState.REQUESTED:
            return "fa-info text-warning fa-blink"
        case AccessPermissionState.REVOKED:
        case AccessPermissionState.RESIGNED:
            return "fa-times-circle-o text-error"
        case AccessPermissionState.CANCELLED:
            return "fa-times-circle-o text-error"
        case AccessPermissionState.REJECTED:
            return "fa-times-circle-o text-error"
        default:
            return ""
    }
}
