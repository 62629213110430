import { formatISO9075 } from "date-fns"
import { timestampToDate } from "./timestampToDate"

export function timestampToDateString(timestamp: number | null): string {
    if (!timestamp) {
        return "-"
    }

    return formatISO9075(timestampToDate(timestamp), { representation: "date" })
}
