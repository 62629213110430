import { Meta } from "../meta"
import { AccessPermission } from "../access-permission"
import { ServiceRequest } from "./request"

export enum ServiceState {
    REQUESTED = "REQUESTED",
    AUTHORIZED = "AUTHORIZED",
    REJECTED = "REJECTED",
    SUSPENDED = "SUSPENDED",
    CANCELLED = "CANCELLED"
}

export enum ServiceType {
    INFRA = "INFRA",
    NORMAL = "NORMAL"
}

export interface Service extends Meta {
    _id: string
    servicePath?: string
    serviceUri: string
    peerId: string
    namespace: string
    name: string
    description: string
    internalUrl: string
    healthcheckUrl: string
    fromDate: number
    toDate?: number
    state: ServiceState
    type: ServiceType
    securityClass: number
    suspendDate?: number
    suspendReason?: string
    permissionRequestsAllowed?: boolean
    async: boolean
    accessPermissions: AccessPermission[]
    needsAction?: boolean
    lastModifierName: string
    lastModifyDate: string
    requests: ServiceRequest[]
}

export interface PublicService {
    _id: string
    name: string
    securityClass: number
}
