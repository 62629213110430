import "es6-promise/auto"
import Vue from "vue"
import { timestampToDateString, timestampToDateTimeString } from "./common/date"
import { getIcon } from "./common/icon"

Vue.mixin({
    methods: {
        timestampToDateString,
        timestampToDateTimeString,
        numberToString: (value: number) => value.toString(),
        stringToNumber: (value: string) => {
            const number = Number.parseInt(value)

            return isNaN(number) ? 0 : number
        },
        getIcon
    }
})

void (async () => {
    // Should initialized before any Vue component import
    const { store } = await import("./store")
    const { i18n } = await import("./i18n")
    const { idomUi } = await import("./idomUi")
    const { router } = await import("./router")

    const App = (await import("./App.vue")).default

    new Vue({
        el: "#app",
        router,
        store,
        i18n,
        idomUi,
        render: h => h(App)
    })
})()
