import { Address } from "../address"
import { Meta } from "../meta"

export enum PeerState {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export interface Peer extends Meta {
    _id: string
    identifier: string
    signatoryId: string
    name: string
    address: Address
    state: PeerState
    needsAction: boolean
}
