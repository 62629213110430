import { Address } from "../address"
import { Meta } from "../meta"

export interface Signatory extends Meta {
    _id: string
    name: string
    description: string
    notProvideElectronicalAdministration: boolean
    notProvideElectronicalAdministrationSubtype?: NotProvideElectronicalAdministrationSubtype
    address: Address
    contractNumbers: string[]
}

export enum NotProvideElectronicalAdministrationSubtype {
    SZEUSZ = "szeusz",
    PKASZ = "pkasz"
}
