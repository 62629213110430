import { Meta } from "../meta"
import { AccessPermissionEventType } from "./event"

export enum AccessPermissionState {
    REQUESTED = "REQUESTED",
    AUTHORIZED = "AUTHORIZED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    REVOKED = "REVOKED",
    RESIGNED = "RESIGNED"
}

export interface AccessPermission extends Meta {
    _id: string
    name: string
    peerId: string
    peerName: string
    signatoryId: string
    signatoryName: string
    serviceId: string
    serviceUri: string
    fromDate: number
    toDate?: number
    state: AccessPermissionState
    stateText: string
    locked?: boolean
    securityClass: number
    legalBasisId: string
    legalBasisCode?: string
    purpose?: string
    needsAction?: boolean
    rateLimit?: number
    contactEmail?: string
    contactPhone?: string
    lastModifierName?: string
    lastModifyDate?: string
    reason?: string
    eventType?: AccessPermissionEventType
}

export interface ListedAccessPermission extends Meta {
    _id: string
    name: string
    serviceUri: string
    peerId: string
    fromDate: number
    toDate?: number
    state: AccessPermissionState
    locked?: boolean
    needsAction?: boolean
    serviceName: string
    peerName: string
    signatoryName: string
    legalBasisCode?: string
}
