export interface PaginateQuery<T> extends PaginateDto {
    query?: T
}

export interface PaginateDto {
    page?: number
    itemsPerPage?: number
    sortColumn?: string
    sortDirection?: DataTableColumnSortDirection
    globalSearchValue?: string
}

export enum DataTableColumnSortDirection {
    ASC = "asc",
    DESC = "desc"
}
