import { getUTCDate } from "./getUTCDate"

export function timestampToDate(timestamp: number, representation?: "date" | "complete" | "time"): Date
export function timestampToDate(timestamp: null, representation?: "date" | "complete" | "time"): null
export function timestampToDate(timestamp: number | null, representation?: "date" | "complete" | "time"): Date | null
export function timestampToDate(timestamp: number | null, representation?: "date" | "complete" | "time"): Date | null {
    if (!timestamp) {
        return null
    }

    if (timestamp < 10000000000) {
        timestamp *= 1000
    }

    return getUTCDate(timestamp, representation)
}
